<template>
    <div class="footer">
        <div class="footer-top">
            <div class="footer-left" @click="goHome">
                <p class="title1">国际互联网教育研究院</p>
                <p class="title2">International Research Institute of ICT-Based Education</p>
            </div>
            <div class="footer-right">
                <div class="about-info">
                    <div class="about-adress">
                        <p>联系我们</p>
                        <p>地址：北京市西城区北三环中路23号燕莎盛世大厦5层</p>
                        <p>电话：15810818941</p>
                        <p>邮箱：chenhy@wedon.com</p>
                    </div>
                    <div class="about-wechat">
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/edu-itc-fe-pc/home/er.png" alt="">
                        <p>移动端</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-bottom">
            <p><a href="https://beian.miit.gov.cn/" target="_blank">京ICP备10026535号-38</a></p>
            <p>2022-2024 北京伟东凌鸿教育科技有限公司所有</p>
        </div>
    </div>
</template>
<script>
export default {
    name:'Footer',
    data(){
        return {
        }
    },
    methods:{
        goHome(){
            this.$router.push({
                path:'/'
            })
        }
    }
}
</script>
<style lang="less" scoped>
.footer{
    width: 100%;
    background:#333333;
    color:#A8A8A8;
    font-size: 12px;
    padding-top: 40px;
    box-sizing: border-box;
    line-height: 20px;
    .footer-top{
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        width: 1200px;
        padding-bottom: 32px;
        justify-content: space-between;
        align-items: center;
        .footer-left{
            color:#fff;
            .title1{
                font-size: 26px;
                margin-bottom:4px;
                line-height: 34px;
                cursor: pointer;
            }
        }
        .footer-right{
            &>p{
                color:#fff;
                font-size: 14px;
            }
            .about-info{
                display: flex;
                justify-content: flex-end;
                font-size: 12px;
                .about-adress p{
                    margin-bottom:8px;
                    &:nth-child(1){
                        color:#fff;
                        margin-bottom: 16px;
                    }
                }
            }
            .about-wechat{
                margin-left:210px;
                text-align:center;
                p{
                    margin-top:4px;
                    font-size: 14px;
                    line-height: 22px;
                }
            }
            img{
                width:120px;
                height:120px;
                display: block;
            }
        }
    }
    .footer-bottom{
        width: 1200px;
        margin:0 auto;
        padding-top: 10px;
        padding-bottom: 10px;
        border-top:1px solid #5A5A5A;
        text-align: center;
        display: flex;
        justify-content: center;
        a{
            color: #A8A8A8;
        }
        p{
            font-size: 12px;
            padding-left:16px;
            padding-right:16px;
            position: relative;
            &:nth-child(1)::after{
                content:'';
                width:1px;
                height:12px;
                top:50%;
                transform: translateY(-50%);
                right: 0;
                background:#5A5A5A;
                position: absolute;
            }
        }
    }
}
</style>